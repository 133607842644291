import React from 'react';
import styled from 'styled-components/macro';
import { graphql } from 'gatsby';

import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

import AboutGoGet from '../views/Jobs/AboutGoGet';
import AppGoGetLayout from '../components/AppGoGetLayout';
import HeaderSeo from '../components/HeaderSeo';
import JobCard from '../views/Jobs/JobCard';
import JobPageAppBar from '../views/Jobs/JobPageAppBar';
import JobPageFooter from '../views/Jobs/JobPageFooter';
import JobShiftModal from '../views/Jobs/JobShiftModal';
import MoreJobsCarousel from '../views/Jobs/MoreJobsCarousel';

import fontSize from '../assets/fontSize';

import { helperJobConversion } from '../services/helperJobConversion';
import { DateFormatter } from '../services/dateFormatter';

const Container = styled.main`
  padding: 0;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  padding-bottom: 64px;

  @media (min-width: 500px) {
    padding: 1rem;
  }
`;
const JobDetailsColumn = styled.div`
  flex: 1;

  @media (min-width: 980px) {
    margin-right: 3rem;
  }
`;
const ShiftsColumn = styled.div`
  width: 35%;
  display: none;

  @media (min-width: 980px) {
    display: block;
  }
`;
const StyledCard = styled(Card)`
  && {
    box-shadow: none;

    @media (min-width: 500px) {
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }
  }
`;
const Content = styled.div`
  padding: 1rem;
`;
const Spacer = styled(Divider)`
  && {
    height: 7px;
    background-color: ${({ transparent }) =>
      transparent ? 'transparent' : 'rgba(0, 0, 0, 0.06)'};
    margin: 0.5rem 0;

    @media (min-width: 500px) {
      display: ${({ desktopHidden }) => (desktopHidden ? 'none' : 'block')};
      background-color: ${({ desktopTransparent }) =>
        desktopTransparent && 'transparent'};
    }
  }
`;
const JobDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin: 1rem;
  margin-bottom: 0.5rem;

  @media (min-width: 500px) {
    margin: 0;
    margin-bottom: 0.5rem;
  }
`;
const SmallText = styled.small`
  font-size: ${fontSize.regular};
`;
const SectionTitle = styled.h4`
  margin: 0;
  font-size: ${fontSize.large};
  font-weight: bold;
`;
const JobDescriptionText = styled.p`
  font-size: ${fontSize.large};
  margin: 1rem 0;
  margin-right: 1rem;
  white-space: pre-wrap;
`;
const PerksList = styled.ul`
  margin: 1rem 0;
  padding: 0 1rem;
`;
const PerkItem = styled.li`
  font-size: ${fontSize.large};
`;
const DesktopHidden = styled.div`
  padding: 1rem 0;

  @media (min-width: 980px) {
    display: none;
  }
`;
const ShiftModalWrapper = styled.div`
  padding: 0 1rem;
  padding-top: 1rem;

  @media (min-width: 500px) {
    padding: 0;
  }
`;
const Capitalize = styled.span`
  text-transform: capitalize;
`;

const ONE_DAY = 24;

const PosterHelperTemplate = props => {
  const { data } = props;
  const { posterHelperType } = data;

  const handleClickOpenApp = label => {
    const url = `${process.env.GATSBY_GOGETTER_APP_URL}/home`;
    window &&
      window.analytics &&
      window.analytics.track('open_app_now', {
        label,
        page_type: 'job_details_page',
        platform: 'static'
      });

    window.open(url, '_blank');
  };

  const requirements =
    posterHelperType?.requirements && posterHelperType?.requirements[0];

  const hoursSincePosted = DateFormatter().diff(
    posterHelperType?.created_at,
    'h'
  );

  const hasRequirements =
    requirements?.dress_code ||
    requirements?.gender ||
    requirements?.interview_method;

  return (
    <AppGoGetLayout logoUrl="/jobs" disableAppBar>
      <HeaderSeo
        title={`${posterHelperType?.poster?.name} ${helperJobConversion(
          posterHelperType?.helper_type
        )} | GoGet Jobs`}
        description={`${posterHelperType?.poster?.name} ${helperJobConversion(
          posterHelperType?.helper_type
        )} job opportunities. Gig jobs available in Kuala Lumpur, Selangor and more.`}
      />
      <JobPageAppBar logoUrl="/jobs" handleClickOpenApp={handleClickOpenApp} />
      <Container>
        <JobDetailsColumn>
          <JobDetailsHeader>
            <SmallText>Type: Hourly Gig</SmallText>
            <SmallText>
              Posted{' '}
              {hoursSincePosted > ONE_DAY
                ? DateFormatter(posterHelperType?.created_at).fromNow()
                : 'less than 1 day ago'}
            </SmallText>
          </JobDetailsHeader>
          <StyledCard>
            <Content>
              <JobCard
                avatar={posterHelperType?.poster?.avatar}
                posterName={posterHelperType?.poster?.name}
                timing={posterHelperType?.timing}
                location={posterHelperType?.location}
                helperType={posterHelperType?.helper_type}
                fee={posterHelperType?.fee}
                publicId={posterHelperType?.poster_id}
                isShowingShare
                isAggregate
              />
            </Content>
            {posterHelperType?.description && (
              <>
                <Spacer />
                <Content>
                  <SectionTitle>Job Description</SectionTitle>
                  <JobDescriptionText>
                    {posterHelperType?.description}
                  </JobDescriptionText>
                </Content>
              </>
            )}
            {hasRequirements && (
              <>
                <Spacer />
                <Content>
                  <SectionTitle>Requirements</SectionTitle>
                  <PerksList>
                    {requirements?.gender && (
                      <PerkItem>
                        Gender: <Capitalize>{requirements?.gender}</Capitalize>
                      </PerkItem>
                    )}
                    {requirements?.dress_code && (
                      <PerkItem>
                        Dress Code:{' '}
                        <Capitalize>{requirements?.dress_code}</Capitalize>
                      </PerkItem>
                    )}
                    {requirements?.interview_method && (
                      <PerkItem>
                        Interview:{' '}
                        <Capitalize>
                          {requirements?.interview_method}
                        </Capitalize>
                      </PerkItem>
                    )}
                  </PerksList>
                </Content>
              </>
            )}
          </StyledCard>
          <DesktopHidden>
            <Spacer desktopTransparent />
            <ShiftModalWrapper>
              <JobShiftModal
                posterId={posterHelperType?.poster_id}
                helperType={posterHelperType?.helper_type}
                location={posterHelperType?.location}
                isMobile
              />
            </ShiftModalWrapper>
          </DesktopHidden>
          <MoreJobsCarousel
            title="Similar jobs"
            jobId={posterHelperType?.job_id}
            sliderName="PosterPage"
            type="Gig"
          />
          <Spacer desktopTransparent />
          <AboutGoGet />
        </JobDetailsColumn>
        <ShiftsColumn>
          <JobShiftModal
            posterId={posterHelperType?.poster_id}
            helperType={posterHelperType?.helper_type}
            location={posterHelperType?.location}
          />
        </ShiftsColumn>
      </Container>
      <JobPageFooter handleClickOpenApp={handleClickOpenApp} />
    </AppGoGetLayout>
  );
};

export default PosterHelperTemplate;

export const query = graphql`
  query PosterHelperQuery($pageIdJim: String) {
    posterHelperType(id: { eq: $pageIdJim }) {
      id
      created_at
      description
      fee
      helper_type
      poster_id
      job_id
      requirements {
        dress_code
        gender
        interview_method
      }
      timing
      location
      poster {
        avatar
        name
      }
    }
  }
`;
