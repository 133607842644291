import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { navigate } from 'gatsby';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import Close from '@material-ui/icons/Close';
import ChevronRight from '@material-ui/icons/ChevronRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

import { getPosterShifts } from '../../services/axios';

const AggregateHeader = styled.div`
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isDisabled }) =>
    isDisabled ? colors.greyLight : colors.appleGreen};
  color: ${({ isDisabled }) => (isDisabled ? colors.grey : colors.black)};
`;
const AggregateTitle = styled.p`
  margin: 0;
  font-size: ${fontSize.medium};
`;
const NoJobsText = styled.p`
  margin: 0;
  font-size: ${fontSize.medium};
  color: ${colors.grey};
`;
const Content = styled.div`
  padding: 1rem;
`;
const StyledButton = styled(Button)`
  && {
    padding: 0.75rem;
    font-family: 'Open Sans';
    justify-content: space-between;
    text-transform: none;
    color: ${colors.greyDark};
  }
`;
const ShiftTimeButton = styled(Button)`
  && {
    padding: 0.75rem;
    justify-content: space-between;
    text-transform: none;
    color: ${colors.black};
  }
`;
const ChevronDown = styled(KeyboardArrowDown)`
  color: ${colors.black};
`;
const DialogTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const DialogTitleText = styled.p`
  margin: 0;
  font-family: 'Open Sans';
  font-size: ${fontSize.xLarge};
  font-weight: 500;
  margin-left: 0.5rem;
`;
const CloseIcon = styled(Close)`
  color: ${colors.black};
`;
const RightArrow = styled(ChevronRight)`
  color: ${colors.black};
`;
const ShiftText = styled.p`
  margin: 0;
  font-family: 'Open Sans';
  font-weight: 200;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 0.75rem;
`;

const NO_AVAILABLE_SHIFTS = 0;
const SINGLE_SHIFT = 1;

const JobShiftModal = props => {
  const { posterId, helperType, isMobile, location } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [jobShifts, setJobShifts] = useState([]);

  useEffect(() => {
    // Get the city from the address
    let newLocation = location.split(',')[1].trim().toLowerCase();

    if (newLocation === 'federal territory of kuala lumpur') {
      newLocation = 'kuala lumpur';
    }

    const fetchPosterShifts = async () => {
      const response = await getPosterShifts(posterId, helperType, [
        newLocation
      ]);

      if (response?.isSuccess) {
        setJobShifts(response?.shifts || []);
      }
    };

    if (posterId && helperType) {
      fetchPosterShifts();
    }
  }, [posterId, helperType, location]);

  const handleClickShift = id => {
    if (id) {
      navigate(`/jobs/hourly-gig/${id}`);
    }
  };

  const renderShifts = () => {
    return (
      <>
        {jobShifts?.map(jobShift => {
          return (
            <ButtonWrapper>
              <ShiftTimeButton
                key={jobShift?.id}
                variant="outlined"
                endIcon={<RightArrow />}
                onClick={() => handleClickShift(jobShift?.public_id)}
                fullWidth
              >
                <div>
                  {jobShift?.shifts?.map(shift => {
                    return <ShiftText key={shift}>{shift}</ShiftText>;
                  })}
                </div>
              </ShiftTimeButton>
            </ButtonWrapper>
          );
        })}
      </>
    );
  };

  return (
    <Card>
      <AggregateHeader
        isDisabled={jobShifts?.length === NO_AVAILABLE_SHIFTS ? 1 : 0}
      >
        <AggregateTitle>
          {jobShifts?.length} job
          {jobShifts?.length === SINGLE_SHIFT ? '' : 's'} available
        </AggregateTitle>
      </AggregateHeader>
      <Content>
        {jobShifts?.length === NO_AVAILABLE_SHIFTS ? (
          <NoJobsText>Please check again later.</NoJobsText>
        ) : (
          <>
            {isMobile ? (
              <>
                <StyledButton
                  variant="outlined"
                  size="large"
                  endIcon={<ChevronDown />}
                  onClick={() => setIsOpen(true)}
                  fullWidth
                >
                  Select Timing
                </StyledButton>
                <Dialog open={isOpen} scroll="paper" fullScreen>
                  <DialogTitle>
                    <DialogTitleWrapper>
                      <IconButton onClick={() => setIsOpen(false)} size="small">
                        <CloseIcon />
                      </IconButton>
                      <DialogTitleText>Select Timing</DialogTitleText>
                    </DialogTitleWrapper>
                  </DialogTitle>
                  <DialogContent dividers>{renderShifts()}</DialogContent>
                </Dialog>
              </>
            ) : (
              renderShifts()
            )}
          </>
        )}
      </Content>
    </Card>
  );
};

export default JobShiftModal;
